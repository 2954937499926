const COMMON_HEADERS = {
	Accept: 'application/json',
	'Content-Type': 'application/json'
};
const FILE_HEADERS = {
	Accept: 'application/octet-stream',
	'Content-Type': 'application/octet-stream'
};

const getToken = async () => {
	return '';
};

const handleResponse = async (response, options = {}) => {
	if (response.status === 200) {
		try {
			if (options.file) {
				const data = await response.blob();
				return { status: API_RESPONSES.SUCCESS, code: response.status, data };
			}
			const data = await response.json();
			return { status: API_RESPONSES.SUCCESS, code: response.status, data };
		} catch (e) {
			return { status: API_RESPONSES.SUCCESS, code: response.status };
		}
	}

	try {
		const error = await response.text();
		return { status: API_RESPONSES.ERROR, message: JSON.parse(error), code: response.status };
	} catch (e) {
		return { status: API_RESPONSES.ERROR, code: response.status };
	}
};

const API = {
	get: async (path = '', options = {}, isPrivate = true) => {
		try {
			const token = isPrivate ? await getToken() : null;
			const headers = options.file ? FILE_HEADERS : COMMON_HEADERS;
			const response = await fetch(path, {
				method: 'GET',
				headers: {
					...headers,
					Authorization: token
				}
			});

			return await handleResponse(response, options);
		} catch (e) {
			return { status: API_RESPONSES.ERROR, message: e, code: e.code || null };
		}
	},

	post: async (path = '', body = {}) => {
		try {
			const token = await getToken();
			const response = await fetch(path, {
				method: 'POST',
				headers: {
					...COMMON_HEADERS,
					Authorization: token
				},
				body: JSON.stringify(body)
			});

			return await handleResponse(response);
		} catch (e) {
			return { status: API_RESPONSES.ERROR, message: e, code: e.code || null };
		}
	},

	put: async (path = '', body = {}) => {
		try {
			const token = await getToken();
			const response = await fetch(path, {
				method: 'PUT',
				headers: {
					...COMMON_HEADERS,
					Authorization: token
				},
				body: JSON.stringify(body)
			});

			return await handleResponse(response);
		} catch (e) {
			return { status: API_RESPONSES.ERROR, message: e, code: e.code || null };
		}
	},

	delete: async (path = '', body = {}) => {
		try {
			const token = await getToken();
			const response = await fetch(path, {
				method: 'DELETE',
				headers: {
					...COMMON_HEADERS,
					Authorization: token
				},
				body: JSON.stringify(body)
			});

			return await handleResponse(response);
		} catch (e) {
			return { status: API_RESPONSES.ERROR, message: e, code: e.code || null };
		}
	}
};

export const API_HOST = process.env.REACT_APP_API_HOST;
export const API_HOST_LINK = process.env.REACT_APP_API_HOST_LINK;
export const PUBLIC_PATH = 'public';
export const PRIVATE_PATH = 'private';
export const API_PUBLIC = `${API_HOST}/${PUBLIC_PATH}/api`;
export const API_PRIVATE = `${API_HOST}/${PRIVATE_PATH}/api`;
export const API_LINKS = `${API_HOST_LINK}/api`;

export const API_PATHS = (search) => {
	const queryParams = search ? `?${search}` : '';
	return {
		LANGUAGES: (language) => `${API_HOST}/${PUBLIC_PATH}/languages/${language}`,
		USER: (rut) => `${API_PRIVATE}/users/${rut}`,
		MERCHANTS: (rut) => ({
			BRANCHES: (branchId) =>
				branchId
					? `${API_PRIVATE}/merchants/${rut}/branches/${branchId}${queryParams}`
					: `${API_PRIVATE}/merchants/${rut}/branches${queryParams}`,
			SUMMARIES: (branchId) =>
				branchId
					? `${API_PRIVATE}/summaries/merchants/${rut}/branches/${branchId}${queryParams}`
					: `${API_PRIVATE}/summaries/merchants/${rut}${queryParams}`,
			CALENDARIES: (branchId) =>
				branchId
					? `${API_PRIVATE}/calendaries/merchants/${rut}/branches/${branchId}${queryParams}`
					: `${API_PRIVATE}/calendaries/merchants/${rut}${queryParams}`,
			INVOICES: (branchId) =>
				branchId
					? `${API_PRIVATE}/sales/merchants/${rut}/invoices/branches/${branchId}${queryParams}`
					: `${API_PRIVATE}/sales/merchants/${rut}/invoices${queryParams}`,
			INVOICES_BY_INVOICE_ID: (invoiceId, branchId) =>
				branchId
					? `${API_PRIVATE}/sales/merchants/${rut}/invoices/${invoiceId}/branches/${queryParams}`
					: `${API_PRIVATE}/sales/merchants/${rut}/invoices/${invoiceId}${queryParams}`,
			ACTIVITIES: (branchId) =>
				branchId
					? `${API_PRIVATE}/activities/merchants/${rut}/branches/${branchId}${queryParams}`
					: `${API_PRIVATE}/activities/merchants/${rut}${queryParams}`,
			REVERSALS: (branchId) =>
				branchId
					? `${API_PRIVATE}/reversals/merchants/${rut}/branches/${branchId}${queryParams}`
					: `${API_PRIVATE}/reversals/merchants/${rut}${queryParams}`,
			PLANS: (paymentPlan) => `${API_PRIVATE}/merchants/${rut}/plans/${paymentPlan}`,
			VOUCHERS: () => `${API_PRIVATE}/vouchers/merchants/${rut}/invoices/${queryParams}`,
			PAYMENTS: (branchId) =>
				branchId
					? `${API_PRIVATE}/payments/merchants/${rut}/branches/${branchId}${queryParams}`
					: `${API_PRIVATE}/payments/merchants/${rut}${queryParams}`,
			EXPORT: (entity) => {
				switch (entity) {
					case 'activities':
						return `${API_PRIVATE}/activities/merchants/${rut}/export${queryParams}`;
					case 'payments':
						return `${API_PRIVATE}/payments/merchants/${rut}/export${queryParams}`;
					case 'sales':
						return `${API_PRIVATE}/sales/merchants/${rut}/invoices/export${queryParams}`;
					case 'reversals':
						return `${API_PRIVATE}/reversals/merchants/${rut}/export${queryParams}`;
					case 'vouchers':
						return `${API_PRIVATE}/vouchers/merchants/${rut}/invoices/export${queryParams}`;
					default:
						return '';
				}
			},
			LINKS: (linkGuid) => {
				if (rut) {
					return `${API_LINKS}/merchants/${rut}/links${queryParams}`;
				} else {
					return `${API_LINKS}/links/${linkGuid}`;
				}
			}
		}),
		MOCKS: (rut) => ({
			BANNERS: () => `${API_PUBLIC}/mocks/banners/${rut}`
		})
	};
};

export const API_RESPONSES = {
	SUCCESS: 'success',
	ERROR: 'error'
};

export default API;
