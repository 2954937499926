import moment from 'moment';

export const inputs = {
	email: {
		regex: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
	},
	password: {
		regex: /^[a-zA-Z0-9!@#$%&\.\^\*\(\)\-\_\+\=]{4,}$/
	}
};

export const getInputTypes = () => {
	return inputs;
};

export const formatCurrency = (n, c, lang) => {
	var c = isNaN((c = Math.abs(c))) ? 2 : c,
		d = lang === 'en' ? '.' : ',',
		t = lang === 'en' ? ',' : '.',
		s = n < 0 ? '-' : '',
		i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
		j = (j = i.length) > 3 ? j % 3 : 0;

	return (
		s +
		(j ? i.substr(0, j) + t : '') +
		i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
		(c ? d + Math.abs(n - i).toFixed(c).slice(2) : '')
	);
};

export const formatCardNumber = (cardNumber) => {
	cardNumber = typeof cardNumber !== 'string' ? cardNumber.toString() : cardNumber;
	return `${cardNumber.substring(0, 4)} ${cardNumber.substring(4, 6)}XX XXXX XXXX`;
};

export const capitalize = (text) => {
	return `${text.substring(0, 1).toUpperCase()}${text.substring(1)}`;
};

export const getMonthDates = (month, year) => {
	const date = moment().month(month).year(year);
	let startDate = date.clone().startOf('month');
	let endDate = date.clone().endOf('month');

	var dates = [];
	var weeks = [];

	var perWeek = [];
	var difference = endDate.diff(startDate, 'days');

	perWeek.push(startDate.toDate());

	let index = 0;
	let lastWeek = false;
	while (startDate.add(1, 'days').diff(endDate) < 0) {
		if (startDate.day() !== 0) {
			perWeek.push(startDate.toDate());
		} else {
			if (startDate.clone().add(7, 'days').month() === month) {
				weeks.push(perWeek);
				perWeek = [];
				perWeek.push(startDate.toDate());
			} else if (Math.abs(index - difference) > 0) {
				if (!lastWeek) {
					weeks.push(perWeek);
					perWeek = [];
				}
				lastWeek = true;
				perWeek.push(startDate.toDate());
			}
		}

		index += 1;

		if (
			(lastWeek === true && Math.abs(index - difference) === 0) ||
			(Math.abs(index - difference) === 0 && perWeek.length === 1)
		) {
			weeks.push(perWeek);
		}

		dates.push(startDate.toDate());
	}

	const startWeekDiff = 7 - weeks[0].length;
	for (let i = 0, l = startWeekDiff; i < l; i += 1) {
		weeks[0].unshift(null);
	}

	const endWeekDiff = 7 - weeks[weeks.length - 1].length;
	for (let i = 0, l = endWeekDiff; i < l; i += 1) {
		weeks[weeks.length - 1].push(null);
	}

	return weeks;
};

export const downloadFile = (file, fileName) => {
	const url = window.URL.createObjectURL(
		new Blob([ file ], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
	);
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', fileName);
	document.body.appendChild(link);
	link.click();
};

export const getFileSize = (url) => {
	return new Promise((resolve) => {
		var fileSize = '';
		var http = new XMLHttpRequest();
		http.open('HEAD', url, true);
		http.onreadystatechange = function() {
			if (this.readyState == this.DONE) {
				if (this.status === 200) {
					fileSize = this.getResponseHeader('content-length');
					resolve(parseInt(fileSize) / 1024 / 1000);
				} else {
					resolve(-1);
				}
			}
		};
		http.send();
	});
};
