import React from 'react';
import { Page } from '../../components';

import makeClasses from './styles';

import appLogoWithSlogan from '../../static/images/logo_with_slogan.svg';
import landingBackground from '../../static/images/background.svg';
import moon from '../../static/images/moon.svg';
import stars from '../../static/images/stars.svg';
import { Colors } from '../../utils';
import { Container, Grid } from '@material-ui/core';

const Landing = () => {
	const classes = makeClasses();

	return (
		<Page bgColor={Colors.rgb.primary}>
			<div className={classes.container}>
				<img src={landingBackground} alt="Background" className={classes.background} />
				<img src={appLogoWithSlogan} alt="CarrotLabs" className={classes.logo} />
				<img src={stars} alt="Stars" className={`${classes.stars} ${classes.starsTopLeft}`} />
				<img src={stars} alt="Stars" className={`${classes.stars} ${classes.starsTopRight}`} />
				<img src={stars} alt="Stars" className={`${classes.stars} ${classes.starsBottomLeft}`} />
				<img src={stars} alt="Stars" className={`${classes.stars} ${classes.starsBottomright}`} />
				<img src={moon} alt="ToTheMoon" className={classes.moon} />
				<div className={classes.slogan}>
					Skyrocket your business<br />to the moon.
				</div>
			</div>
			<div className={classes.section}>
				<Container>
					<Grid container>
						<Grid item xs={12} sm={12} md={12}>
							<div className={classes.quoteWrapper}>
								<div className={classes.quote}>
									<q>
										The ones who are <i>crazy enough</i> to think they can change the world, are the ones <i>who do</i>.
									</q>
									<span className={classes.quoteAuthor}>- Steve Jobs.</span>
								</div>
							</div>
						</Grid>
					</Grid>
				</Container>
			</div>
		</Page>
	);
};

export default Landing;
