import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { connectRouter, routerMiddleware, push } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import stateReconciler from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import * as reducers from '../reducers';
import rootSaga from '../sagas';

const persistConfig = {
	key: 'root',
	storage,
	stateReconciler,
	blacklist: [ 'router' ]
};

const configureStore = (initialState = {}, history) => {
	const rootReducer = combineReducers({
		...reducers,
		router: connectRouter(history)
	});
	const persistedReducer = persistReducer(persistConfig, rootReducer);

	const sagaMiddleware = createSagaMiddleware();

	const middlewares = [ routerMiddleware(history) ];

	const enhancers = [ applyMiddleware(...middlewares) ];

	const store = createStore(
		connectRouter(history)(persistedReducer),
		applyMiddleware(sagaMiddleware),
		compose(...enhancers)
	);

	sagaMiddleware.run(rootSaga);

	const persistor = persistStore(store, null, () => {});

	return { store, persistor };
};

export default configureStore;
