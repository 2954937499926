import { createMuiTheme } from '@material-ui/core/styles';
import { Colors } from './utils';

const theme = createMuiTheme({
	palette: {
		primary: {
			main: Colors.rgb.primary
		},
		secondary: {
			main: Colors.rgb.secondary
		}
	}
});

export default theme;
