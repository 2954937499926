import moment from 'moment';

let i18n = {};
let locale = 'es';
let requestingTexts = null;

/*if (navigator.languages && navigator.languages.length) {
	locale = navigator.languages[0];
} else {
	locale = navigator.language || navigator.browserLanguage || 'es';
}

if (locale.indexOf('-') >= 0) {
	locale = locale.split('-')[0];
} else if (locale.indexOf('_') >= 0) {
	locale = locale.split('_')[0];
}*/

export const getLocale = () => {
	const hasTexts = Object.keys(i18n).length > 0;

	if (hasTexts) {
		return i18n;
	}

	if (requestingTexts) {
		return requestingTexts;
	}

	requestingTexts = new Promise(async (resolve) => {
		//let translations = null;
		/*try {
			const result = await API.get(API_PATHS().LANGUAGES(locale.toUpperCase()));
			if (result.status === 'success' && result.data) {
				i18n = result.data;
			} else {
				throw result.message;
			}
		} catch (e) {*/
		i18n = require('./locales/es_es.json');
		//}

		resolve(i18n);
	});

	return requestingTexts;
};

export const Translations = {
	get: async (key) => {
		if (!i18n) {
			i18n = await getLocale();
		}

		const keyPath = key.split('.');
		let objectPath = null;

		for (let i = 0; i < keyPath.length; i += 1) {
			if (typeof i18n[keyPath[i]] === 'undefined') {
				break;
			} else {
				objectPath = i18n[keyPath[i]];
			}
		}

		return objectPath;
	}
};

export const setMomentLocale = async (locale) => {
	switch (locale) {
		case 'es':
			require('../../../node_modules/moment/locale/es');
			break;
		default:
			require('../../../node_modules/moment/locale/es');
			break;
	}

	moment.locale(locale);
};

setMomentLocale(locale);
