import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles((theme) => ({
	container: {
		backgroundColor: Colors.rgb.primary,
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		overflow: 'hidden',
		position: 'relative',

		[theme.breakpoints.down('sm')]: {
			justifyContent: 'inherit'
		}
	},
	background: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		objectFit: 'cover',
		width: '100%',
		height: '100%',
		zIndex: 0
	},
	logo: {
		position: 'absolute',
		top: '6%',
		width: 250,
		zIndex: 10,

		[theme.breakpoints.down('sm')]: {
			width: 225
		}
	},
	stars: {
		position: 'absolute',
		width: 350,
		zIndex: 1,
		objectFit: 'cover',

		[theme.breakpoints.down('sm')]: {
			width: 220
		}
	},
	starsTopLeft: {
		top: '8%',
		left: 0
	},
	starsBottomLeft: {
		bottom: 0,
		left: '5%'
	},
	starsTopRight: {
		top: '2%',
		right: '2%'
	},
	starsBottomright: {
		bottom: '5%',
		right: '5%'
	},
	moon: {
		position: 'absolute',
		bottom: 0,
		width: 520,
		zIndex: 1,
		objectFit: 'cover',

		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	slogan: {
		position: 'absolute',
		fontSize: '4rem',
		fontWeight: 'bold',
		color: Colors.rgb.white,
		textAlign: 'center',
		zIndex: 2,
		textShadow: `0 3px 5px ${Colors.rgba(Colors.rgb.black, 30)}`,
		letterSpacing: 4.88,
		lineHeight: '5.5rem',
		top: '41%',

		[theme.breakpoints.down('sm')]: {
			fontSize: '2.4rem',
			lineHeight: '2.9rem',
			letterSpacing: 4.08,
			top: '42%'
		}
	},
	section: {
		padding: '150px 0',
		backgroundColor: Colors.rgb.primary,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',

		[theme.breakpoints.down('sm')]: {
			padding: '60px 0'
		}
	},
	quoteWrapper: {},
	quote: {
		fontSize: '2.5rem',
		fontWeight: 'bold',
		color: Colors.rgb.light_black,
		textAlign: 'center',

		'& i': {
			color: Colors.rgb.secondary
		},

		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem'
		}
	},
	quoteAuthor: {
		marginLeft: 10
	}
}));

export default makeClasses;
