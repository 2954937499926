import React from 'react';

import makeClasses from './styles';

function Page({ children, bgColor = null }) {
	const classes = makeClasses();

	return (
		<div className={classes.root} style={{ backgroundColor: bgColor }}>
			{children}
		</div>
	);
}

export default Page;
