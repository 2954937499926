import React from 'react';
import { Route, Switch } from 'react-router'; // react-router v4/v5

import Landing from '../Landing';

const App = () => (
	<Switch>
		<Route exact path="/" component={Landing} />
	</Switch>
);

export default App;
